<template>
  <div>
    <RecipesList :type="type"></RecipesList>
  </div>
</template>

<script>
  import { RecipeType } from '@/core/constants/enums';
  import RecipesList from '@/views/components/recipe/RecipesList';

  export default {
    name: 'RecipeBakeryList',
    components: { RecipesList },
    data: () => ({
      type: RecipeType.BAKERY
    })
  };
</script>

<style></style>
